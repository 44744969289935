import {toLiteral, toArrayLiteral} from "./Store";
import Sync from "./Sync";

export {create} from "zustand";
export {toLiteral, toArrayLiteral};

const deepClone = function (obj) {
    let clone = obj;
    if (Array.isArray(obj)) {
        clone = [];
        obj.forEach((item, index) => {
            clone[index] = deepClone(item);
        });
    } else if (obj instanceof Date) {
        return new Date(obj.getTime());
    } else if (obj && typeof obj === "object") {
        clone = {};
        Object.keys(obj).forEach((key) => {
            clone[key] = deepClone(obj[key]);
        });
    }
    return clone;
};

export default class Model {
    constructor(set, get, options) {
        this.sync = new Sync();
        this.options = options || {};
        this.idAttribute = "id";
        this._request = {
            isSynced: false,
        };

        // by default, no shared store, only local attributes literal
        this.setState = set || this._localSet;
        this.getState = get || this._localGet;
    }
    _localSet(attributes) {
        this.attributes = attributes;
    }
    _localGet() {
        return this.attributes || {};
    }
    url() {
        return this.options.url;
    }
    urlRoot() {
        return;
    }
    props() {
        return this.options.props || {};
    }
    computed() {
        return this.options.computed || {};
    }
    isSynced() {
        return this._request.isSynced;
    }
    fetch(qs, options) {
        qs = qs || {};
        options = options || {};
        const url = this._fullURL(options) + this.sync.searchUrl(qs);
        this.setState({_request: {isSynced: false}});

        return fetch(url, this.sync.fetchOptions())
            .then((res) => this.sync.readJSON(res))
            .then((json) => this._parseJSON(json))
            .then((json) => this.set(json, {merge: false}))
            .catch(() => this.setState({_request: {isSynced: true}}));
    }
    save(data, options) {
        options = options || {};
        options.isBasicSave = true;

        const json = this._pickByProps(data);
        this.setState({_request: {isSynced: false}});

        return this.saveRaw(json, options)
            .then((json) => this._parseJSON(json))
            .then((json) => this.set(json, {merge: false}))
            .catch(() => this.setState({_request: {isSynced: true}}));
    }
    saveRaw(data, options) {
        options = options || {};

        return fetch(
            this._fullURL(options, data),
            this.sync.fetchOptions({
                method: options.method || this._updateMethod(data),
                headers: {
                    "Content-Type": "application/json",
                },
                body: this.stringifyRaw(data, options),
            }),
        ).then((res) => this.sync.readJSON(res));
    }
    _pickByProps(data) {
        const json = this.toJSON();
        if (data) {
            Object.keys(this.props()).forEach((prop) => {
                // eslint-disable-next-line no-prototype-builtins
                if (data.hasOwnProperty(prop)) json[prop] = data[prop];
            });
        }
        return json;
    }
    stringify(data) {
        return JSON.stringify(data);
    }
    stringifyRaw(data, options) {
        if (!options.isBasicSave)
            // calling saveRaw directly should not use (possibly) overridden stringify
            return JSON.stringify(deepClone(data));

        return this.stringify(deepClone(data));
    }
    destroy(options) {
        options = options || {};

        return fetch(
            this._fullURL(options),
            this.sync.fetchOptions({
                method: "DELETE",
            }),
        )
            .then((res) => this.sync.readJSON(res))
            .then((json) => this._parseJSON(json))
            .then((json) => this.set(json, {merge: false}));
    }
    _updateMethod(state) {
        return state[this.idAttribute] ? "PUT" : "POST";
    }
    _fullURL(options, data) {
        const state = data || this.getState();
        const idPath = !state[this.idAttribute] ? "" : "/" + state[this.idAttribute];
        return options.url || (this.urlRoot() || this.url()) + idPath;
    }
    parse(data) {
        return data;
    }
    _parseJSON(item) {
        return this._parseItem(this.parse(item));
    }
    _parseItem(item) {
        const parsed = {};
        Object.keys(this.props()).forEach((prop) => {
            if (item[prop] === undefined || item[prop] === null) {
                if ([String, Number, Boolean].includes(this.props()[prop])) parsed[prop] = "";
                else parsed[prop] = item[prop];
                return;
            }

            switch (this.props()[prop]) {
                case String:
                    parsed[prop] = String(item[prop]);
                    break;
                case Number:
                    parsed[prop] = Number(item[prop]);
                    break;
                case Boolean:
                    parsed[prop] = Boolean(item[prop]);
                    break;
                case Array:
                    parsed[prop] = Array.from(item[prop]);
                    break;
                case Object:
                    parsed[prop] = Object.assign({}, item[prop]);
                    break;
                case Date:
                    parsed[prop] = item[prop] && new Date(item[prop]);
                    break;
                default:
                    break;
            }
        });

        return parsed;
    }
    set(data, options) {
        options = {merge: true, ...options};
        const nextState = {_request: {isSynced: true}};

        Object.keys(this.props()).forEach((prop) => {
            if (options.merge === false || data[prop] !== undefined) nextState[prop] = data[prop];
        });

        this.setState(nextState);
        return this;
    }
    clear() {
        const nextState = {};

        Object.keys(this.props()).forEach((prop) => {
            switch (this.props()[prop]) {
                case Array:
                    nextState[prop] = [];
                    break;
                case Object:
                    nextState[prop] = {};
                    break;
                default:
                    nextState[prop] = undefined;
                    break;
            }
        });

        this.setState(nextState);
        return this;
    }
    toJSON() {
        const json = {};
        const state = this.getState();
        Object.keys(this.props()).forEach((prop) => {
            json[prop] = deepClone(state[prop]);
        });
        return json;
    }
}
